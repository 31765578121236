import React from 'react';
import { AppBar, Toolbar, Typography, Container } from '@mui/material';
// import Background from '../assets/header.png';

const Footer = ({ color }) => {

  return (
    <AppBar 
      position="static"
      sx={{
        marginTop: '100px',
        // backgroundImage: `url(${Background})`, // Update with your image path
        backgroundColor: color ? color : '#33c4f9',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        minHeight: '50px'
      }}
    >
      <Container>
        <Toolbar>
          <Typography sx={{ flexGrow: 1, fontWeight: 'bold' }}>
            &copy; {new Date().getFullYear()} DTIKETIN
          </Typography>
          <Typography></Typography>
          <Typography sx={{ marginLeft: 2 }}> </Typography>
          <Typography sx={{ marginLeft: 2 }}>Whatsapp: 085710770869 | Email: support@dtiketin.com</Typography>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Footer;
