import React, { useState } from 'react';
// import Navigation from './shared/navigation';
import Link from '@mui/material/Link';
// import Socials from '@components/other/socials';
import classNames from 'classnames';
import { renderThemeClass } from '../functions';
import { Button } from '@mui/material';
import logo from '../../assets/dtiketinwhite.png';
import { useNavigate } from 'react-router-dom';

const Header = ({ theme, color }) => {
  const navigate = useNavigate();

  const [isOpenNavMobile, setIsOpenNavMobile] = useState(false);
  const [shouldShowSearch, setShouldShowSearch] = useState(false);

  return (
    <>
      <div className="header-spacing" />
      <header className={classNames(renderThemeClass(theme))} style={{backgroundColor: color}}>
        <div className="container">
          <div className="header-wrapper">
            <Link href="#" className="header-logo"  onClick={() => navigate('/')} >
              <img src={logo} alt="Dtiketin" style={{ width: '150px', marginRight: '16px' }} />
            </Link>
            {/* <Navigation isOpenNavMobile={isOpenNavMobile} setIsOpenNavMobile={setIsOpenNavMobile} /> */}
            {/* <div className="header-icons">
              <Button
                onClick={() => setShouldShowSearch(!shouldShowSearch)}
                color={theme}
                className="header-icons__search header-icons__item"
                variant="link">
                <i className="fas fa-search" />
              </Button>
              <div className="social">
                <Button
                  color={theme}
                  onClick={() => setIsOpenNavMobile(!isOpenNavMobile)}
                  className="header-icons__item header-icons__menu-controller"
                  variant="link">
                  <i className="fas fa-bars" />
                </Button>
              </div>
            </div> */}
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
