import React from 'react';
import ParticipantsTable from './ParticipantsTable';

const ParticipantsList = () => {
  const events = [
    { id: 1, name: "Event 1" },
    { id: 2, name: "Event 2" },
    { id: 3, name: "Event 3" },
  ];

  const participants = [
    { id: 1, name: "John Doe", email: "john@example.com", eventId: 1 },
    { id: 2, name: "Jane Smith", email: "jane@example.com", eventId: 2 },
    { id: 3, name: "Alice Johnson", email: "alice@example.com", eventId: 3 },
    { id: 4, name: "Bob Brown", email: "bob@example.com", eventId: 1 },
    { id: 5, name: "Charlie Davis", email: "charlie@example.com", eventId: 2 },
  ];

  return (
    <div>
      <ParticipantsTable participants={participants} events={events} />
    </div>
  );
};

export default ParticipantsList;
