import axios from 'axios';
import { REACT_APP_API_URL } from './api';

// console.log(process.env.REACT_APP_API_URL);
// console.log(REACT_APP_API_URL);
const ConnectionInstance = axios.create({
  baseURL: REACT_APP_API_URL, //Prod
  // baseURL: process.env.REACT_APP_API_URL, //Localhost
  timeout: 10000,
});

// Add a request interceptor to include the Bearer token if available
ConnectionInstance.interceptors.request.use(
  (config) => {
    const accessToken = localStorage.getItem('accessToken'); // Retrieve token from localStorage

    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`; // Add Bearer token to headers if it exists
    }

    return config;
  },
  (error) => {
    // Handle request errors
    return Promise.reject(error);
  }
);

// Handle response errors (e.g., 401 or 500 errors)
ConnectionInstance.interceptors.response.use(
  (response) => {
    // Successful response, return it
    return response;
  },
  (error) => {
    if (error.response) {
      const status = error.response.status;

      // Handle 401 Unauthorized
      if (status === 401) {
        console.error('Unauthorized Access:', error.response.data);
        // Optionally log out or refresh token here
        // logoutUser(); or refreshToken();

        // Redirect to login or handle in another way
        localStorage.removeItem('accessToken');
        window.location.reload();

        return Promise.reject(error); // Let the app handle it further (e.g., show error message)
      }

      // Handle 500 Internal Server Error
      if (status === 500) {
        console.error('Internal Server Error:', error.response.data);
        return Promise.reject(error); // Propagate the error for further handling
      }
    } else {
      console.error('Network or unknown error:', error.message);
    }

    return Promise.reject(error); // Always propagate the error to handle it in calling functions
  }
);

export default ConnectionInstance;
