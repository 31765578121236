import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  login: {
    status: null,
    error: null,
  },
};

const RequestStatus = {
  Loading: 'loading',
  Success: 'success',
  Failed: 'failed',
};

// Reducer functions
const loginRequest = (state) => {
  delete state.login.error;
  state.login.status = RequestStatus.Loading;
};

const loginSuccess = (state) => {
  state.login.status = RequestStatus.Success;
};

const loginFailed = (state, { payload }) => {
  state.login.error = payload;
  state.login.status = RequestStatus.Failed;
};
// Create the slice
const authSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    loginRequest,
    loginSuccess,
    loginFailed,
  },
});

// Export actions and reducer
export const authActions = authSlice.actions;
export const authReducer = authSlice.reducer;
