import { combineReducers } from 'redux';
import { eventReducer } from '../slices/events';
import { checkoutReducer } from '../slices/checkout';
import { commonReducer } from '../slices/common';
import { jerseyReducer } from '../slices/jersey';
import { authReducer } from '../slices/auth';
import { dashboardReducer } from '../slices/dashboard';

const rootReducer = combineReducers({
    common: commonReducer,
    checkout: checkoutReducer,
    event: eventReducer,
    jersey: jerseyReducer,
    auth: authReducer,
    dashboard: dashboardReducer,
});

export default rootReducer;
