import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  dashboard: {
    status: null,
    error: null,
  },
  list: {
    fetching: false,
    error: null,
    data: null,
  },
  search: {
    fetching: false,
    error: null,
    data: null,
  },
  dataPostClaimed: {
    status: null,
    error: null,
    data: []
  }
};

const RequestStatus = {
  Loading: 'loading',
  Success: 'success',
  Failed: 'failed',
};

const jerseyCountRequest = (state) => {
  delete state.list.error;
  state.list.fetching = true;
};

const jerseyCountSuccess = (state, { payload }) => {
  state.list.data = payload.data;
  state.list.fetching = false;
};

const jerseyCountFailed = (state, { payload }) => {
  state.list.error = payload;
  state.list.fetching = false;
};

const searchClaimedReq = (state) => {
  delete state.search.error;
  state.search.fetching = true;
};

const searchClaimedSuccess = (state, { payload }) => {
  state.search.data = payload.data;
  state.search.fetching = false;
};

const searchClaimedFailed = (state, { payload }) => {
  state.search.error = payload;
  state.search.fetching = false;
};

const postClaimedReq = (state) => {
  delete state.dataPostClaimed.error;
  state.dataPostClaimed.status = RequestStatus.Loading;
};

const postClaimedSuccess = (state, {payload}) => {  
  console.log(payload);
  state.dataPostClaimed.data =  payload.result.result;
};

const postClaimedFailed = (state, { payload }) => {
  state.dataPostClaimed.error = payload;
  state.dataPostClaimed.status = RequestStatus.Failed;
};
// Create the slice
const dashboard = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    jerseyCountRequest,
    jerseyCountSuccess,
    jerseyCountFailed,

    searchClaimedReq,
    searchClaimedSuccess,
    searchClaimedFailed,

    postClaimedReq,
    postClaimedSuccess,
    postClaimedFailed
  },
});

// Export actions and reducer
export const dashboardActions = dashboard.actions;
export const dashboardReducer = dashboard.reducer;
