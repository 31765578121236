import { dashboardActions } from '../slices/dashboard';
import { countClaimed, fetchSearchClaimed, postClaimed } from '../../services/dashboard'; 
import { commonActions } from '../slices/common';

export const getCountClaimed = (param) => async (dispatch) => {
  try {
    dispatch(dashboardActions.jerseyCountRequest());
    const data = await countClaimed(param);
    dispatch(dashboardActions.jerseyCountSuccess(data));
  } catch (error) {
    console.log(error);
    dispatch(dashboardActions.jerseyCountFailed(error));
    dispatch(commonActions.showToast({ message: 'Claim Failed, please try again', type: 'error' }));
    throw error;
  }
};

export const searchClaimed = (param) => async (dispatch) => {
  try {
    dispatch(dashboardActions.searchClaimedReq());
    const data = await fetchSearchClaimed(param);
    dispatch(dashboardActions.searchClaimedSuccess(data));
  } catch (error) {
    console.log(error);
    dispatch(dashboardActions.searchClaimedFailed(error));
    dispatch(commonActions.showToast({ message: 'Scan QR Failed, please try again', type: 'error' }));
    throw error;
  }
};

export const postClaim = (param) => async (dispatch) => {
  try {
    dispatch(dashboardActions.postClaimedReq());
    const resData = await postClaimed(param);
    const res = resData.data;
    console.log(res);
    
    dispatch(dashboardActions.postClaimedSuccess(res));
    if(res.code === 0){
      dispatch(commonActions.showToast({ message: 'Checkout success', type: 'success' }));
    }else{
      dispatch(commonActions.showToast({ message: res.msg, type: 'error' }));
    }
    return res;
  } catch (error) {
    console.log(error);
    dispatch(dashboardActions.postClaimedFailed(error));
    dispatch(commonActions.showToast({ message: 'Claim, please try again', type: 'error' }));
    throw error;
  }
};
