import React, { useEffect } from 'react';
import { Paper, Typography, Box, Button, Link, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { useLocation, useNavigate} from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { handleGetOrder } from '../../store/thunk/checkout.js';

const PaymentSuccess = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const searchParams = new URLSearchParams(location.search);
  // Extract query parameters
  const queryParams = new URLSearchParams(location.search);
  const orderId = queryParams.get('order_id');
  const statusCode = queryParams.get('status_code');
  const transactionStatus = searchParams.get('transaction_status');
  // const id = searchParams.get('order_id');

  const { data, fetching, error } = useSelector((state) => state.checkout.list);

  useEffect(() => {
    if (orderId) {
      dispatch(handleGetOrder(orderId, transactionStatus));
    }else{
      // navigate('/notfound');
    }

  }, [orderId, transactionStatus, navigate, dispatch]);

  if (fetching) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  const order = data?.result ?? {};
  const parsedPayment = JSON.parse(order.payment ?? '{}');

  const eventName = order?.booking?.event?.event_name;
  const jumlahBookingTicket = order?.booking?.booking_tickets.length;

  return (
    <Paper elevation={3} style={{ padding: '20px', borderRadius: '10px', textAlign: 'center' }}>
      {transactionStatus === 'settlement' ? (
        <CheckCircleOutlineIcon style={{ fontSize: '50px', color: 'green' }} />
      ) : (
        <RemoveCircleOutlineIcon style={{ fontSize: '50px', color: 'red' }} />
      )}
      <Typography variant="h4" gutterBottom>
        {transactionStatus  === 'settlement' ? 'Payment Successful!' : 'Payment Failed!'}
      </Typography>
      {transactionStatus && (
        <Typography variant="body1" gutterBottom>
          Thank you for your payment.
        </Typography>
      )}
      
      <Box mt={3}>
        <Typography variant="h6" gutterBottom>
          Booking Details
        </Typography>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell style={{ width: '40%' }}><strong>Field</strong></TableCell>
                <TableCell style={{ width: '60%' }}><strong>Value</strong></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>{order.booking?.booking_id ?? '-'}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>{order.booking?.booking_full_name ?? '-'}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Phone</TableCell>
                <TableCell>{order.booking?.booking_phone_number ?? '-'}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Email</TableCell>
                <TableCell>{order.booking?.booking_email ?? '-'}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      <Box mt={3}>
        <Typography variant="h6" gutterBottom>
          Payment Details
        </Typography>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell style={{ width: '40%' }}><strong>Item</strong></TableCell>
                <TableCell style={{ width: '60%' }}><strong>Cost</strong></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {/* {order.tenKParticipantsCount > 0 ? <TableRow>
                <TableCell>10K RACE Cost</TableCell>
                <TableCell>Rp. {parsedPayment.formTenK ?? 0}</TableCell>
              </TableRow> : ''}
              {order.fiveKParticipantsCount > 0 ? <TableRow>
                <TableCell>5K FUN Cost</TableCell>
                <TableCell>Rp. {parsedPayment.formFiveK ?? 0}</TableCell>
              </TableRow> : ''}
              {order.threeKFamilyRunParticipantsCount > 0 ? <TableRow>
                <TableCell>3K FAMILY RUN Cost</TableCell>
                <TableCell>Rp. {parsedPayment.formThreeKFam ?? 0}</TableCell>
              </TableRow> : ''} */}
              {
                order?.paymentTicket?.map((ticket,i) => {
                  return(
                  <>
                    <TableRow>
                      <TableCell>{ticket?.ticket_name} ({ticket?.countData})</TableCell>
                      <TableCell>Rp. {(ticket?.value).toLocaleString('id-ID') ?? 0}</TableCell>
                    </TableRow>
                  </>);
                })
              }
              <TableRow>
                {
                  eventName === "HEROES TRAIL RUN" ?
                  <>
                    <TableCell>Tiket masuk Gunung Mas Rp 20.000/Ticket:</TableCell>
                    <TableCell>Rp. {(jumlahBookingTicket * 20000).toLocaleString('id-ID') ?? 0}</TableCell>
                  </> :
                  <>
                    <TableCell>Tax (5%):</TableCell>
                    <TableCell>Rp. {parsedPayment?.totalCost !== undefined ? ((parsedPayment?.totalCost) * 0.05).toLocaleString('id-ID') : 0}</TableCell>
                  </>
                }
              </TableRow>
              <TableRow>
                <TableCell>Convenience Fee:</TableCell>
                <TableCell>Rp. 6.000</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Service Charge (4%):</TableCell>
                <TableCell>Rp. {(parsedPayment?.totalCost * 0.04).toLocaleString('id-ID') ?? 0}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell><b>Total Cost</b></TableCell>
                <TableCell>
                  {
                    parsedPayment?.sumTicketCost !== undefined ? (
                      <b>Rp. {(parsedPayment?.sumTicketCost).toLocaleString('id-ID')}</b>
                    ) : (
                      0
                    )
                  }
                </TableCell>
              </TableRow>
              {/* <TableRow>
                <TableCell>Redeem Code</TableCell>
                <TableCell>{parsedPayment.redeemCode ?? '-'}</TableCell>
              </TableRow> */}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      <Box mt={3}>
        <Typography variant="h6" gutterBottom>
          Participants
        </Typography>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell style={{ width: '40%' }}><strong>Event</strong></TableCell>
                <TableCell style={{ width: '60%' }}><strong>Participants</strong></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {/* {order.tenKParticipantsCount > 0 ? <TableRow>
                <TableCell>10K RACE Participants</TableCell>
                <TableCell>{order.tenKParticipantsCount}</TableCell>
              </TableRow>:''}
              {order.fiveKParticipantsCount > 0 ? <TableRow>
                <TableCell>5K FUN RUN Participants</TableCell>
                <TableCell>{order.fiveKParticipantsCount}</TableCell>
              </TableRow>:''}
              {order.threeKFamilyRunParticipantsCount > 0 ? <TableRow>
                <TableCell>3K FAMILY RUN Participants</TableCell>
                <TableCell>{order.threeKFamilyRunParticipantsCount}</TableCell>
              </TableRow>:''} */}
              {
                order?.paymentTicket?.map((ticket,i) => {                  
                  return(
                  <>
                    <TableRow>
                      <TableCell>{ticket?.ticket_name}</TableCell>
                      <TableCell>{ticket?.countData}</TableCell>
                    </TableRow>
                  </>);
                })
              }
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      <Box mt={3}>
        <Button variant="contained" color="primary" onClick={() => window.print()} className="no-print">
          Print Receipt
        </Button>
      </Box>

      <Box mt={3}>
        <Link href="/" style={{ textDecoration: 'none' }} className="no-print">
          Back to home
        </Link>
      </Box>
    </Paper>
  );
};

export default PaymentSuccess;
