import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  toast: {
    visible: false,
    message: 'Some message',
    type: 'success' | 'error' | 'warn' | 'info',
  },
};

const showToast = (state, { payload }) => {
  state.toast.visible = true;
  state.toast.message = payload.message;
  state.toast.type = payload.type;
};

const hideToast = (state) => {
  state.toast.visible = false;
};

const commonSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    showToast,
    hideToast,
  },
});

export const commonActions = commonSlice.actions;
export const commonReducer = commonSlice.reducer;
