import { eventActions } from '../slices/events'; // Adjust the import path as necessary
import { getEvents, getEventsDetail, getTickets } from '../../services/event'; // Adjust the import path as necessary

export const handleGetEvent = (param) => async (dispatch) => {
  try {
    dispatch(eventActions.getEventRequest());
    const response = await getEvents(param);
    dispatch(eventActions.getEventSuccess(response.data));
  } catch (error) {
    dispatch(eventActions.getEventFailed(error.message));
  }
};

export const handleGetEventDetail = (id) => async (dispatch) => {
  try {
    dispatch(eventActions.getEventDetailRequest());
    const { data } = await getEventsDetail(id);
    dispatch(eventActions.getEventDetailSuccess(data));
  } catch (error) {
    dispatch(eventActions.getEventDetailFailed(error.message));
  }
};

export const handleGetTicket = (id) => async (dispatch) => {
  try {
    dispatch(eventActions.getTicketRequest());
    const { data } = await getTickets(id);
    dispatch(eventActions.getTicketSuccess(data));
  } catch (error) {
    dispatch(eventActions.getTicketFailed(error.message));
  }
};
