import React from 'react';
import { Card, CardContent, Grid, Skeleton } from '@mui/material';

const SkeletonCard = () => (
  <Card>
    <Skeleton variant="rectangular" width="100%" height={140} />
    <CardContent>
      <Skeleton variant="text" width="60%" />
      <Skeleton variant="text" width="80%" />
      <Skeleton variant="text" width="40%" />
      <Grid container spacing={1} alignItems="center">
        <Grid item>
          <Skeleton variant="circular" width={24} height={24} />
        </Grid>
        <Grid item>
          <Skeleton variant="text" width="40%" />
        </Grid>
      </Grid>
    </CardContent>
  </Card>
);

export default SkeletonCard;
