import React from 'react';
import {
  Grid,
  Box,
  Skeleton,
  CardActionArea,
  Card,
  CardContent,
} from '@mui/material';

const EventDetailSkeleton = () => {
    return (
        <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
            <Skeleton variant="rectangular" height={200} />
            </Grid>
            <Grid item xs={12} md={8}>
            <CardActionArea component="a" href="#">
                <Card sx={{ display: 'flex' }}>
                <CardContent sx={{ flex: 1 }}>
                    <Skeleton variant="text" sx={{ fontSize: '1.5rem', mb: 1 }} />
                    <Skeleton variant="text" sx={{ fontSize: '1rem', mb: 1 }} />
                    <Skeleton variant="text" sx={{ fontSize: '1rem', width: '30%' }} />
                </CardContent>
                <Skeleton variant="rectangular" width={160} height={140} />
                </Card>
            </CardActionArea>
            <Card style={{ marginTop: '20px' }}>
                <CardContent>
                <Skeleton variant="text" sx={{ fontSize: '1.5rem', mb: 2 }} />
                <Skeleton variant="text" sx={{ fontSize: '1rem', mb: 2 }} />
                <Skeleton variant="rectangular" height={200} />
                <Box sx={{ mt: 3 }}>
                    <Skeleton variant="text" sx={{ fontSize: '1.5rem', mb: 2 }} />
                    <Skeleton variant="rectangular" height={400} />
                </Box>
                </CardContent>
            </Card>
            </Grid>
            <Grid item xs={12} md={4}>
            <Card>
                <CardContent>
                <Skeleton variant="text" sx={{ fontSize: '1.5rem', mb: 2 }} />
                <Skeleton variant="text" sx={{ fontSize: '1rem', mb: 2 }} />
                <Skeleton variant="text" sx={{ fontSize: '1rem', mb: 1 }} />
                <Skeleton variant="text" sx={{ fontSize: '1rem', width: '50%' }} />
                <Skeleton variant="text" sx={{ fontSize: '1rem', mb: 2 }} />
                <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                <Box sx={{ mt: 2 }}>
                    <Skeleton variant="rectangular" height={50} />
                </Box>
                </CardContent>
            </Card>
            <Box sx={{ mt: 2 }}>
                <Skeleton variant="rectangular" height={50} />
            </Box>
            </Grid>
        </Grid>
    );
}
export default EventDetailSkeleton;
