import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Container, Stack, Breadcrumbs, Link, Button, Typography, Card, CardContent, Grid, IconButton } from '@mui/material';
import ThemeVariation from '../../theme/theme-variation.js';
import Layout from '../layout/index.jsx';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import CountUp from 'react-countup';
import { handleGetJawararun, handleGetHeroes } from '../../store/thunk/checkout.js';
import RefreshIcon from '@mui/icons-material/Refresh';

const Monitor = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  
  const { data, fetching, error } = useSelector((state) => state.checkout.list);
  
  useEffect(() => {
    if(id === 'jawararun'){
      dispatch(handleGetJawararun());
    }else if(id === 'heroestrailrun'){
      dispatch(handleGetHeroes());
    } else {
      navigate('404')
    }
    
  }, [dispatch, id, navigate]);

  const handleReload = () => {
    if(id === 'jawararun'){
      dispatch(handleGetJawararun());
    }else if(id === 'heroestrailrun'){
      dispatch(handleGetHeroes());
    } else {
      navigate('404')
    }
  };

  if (fetching) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

  const THEME = ThemeVariation.Secondary;

  const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" href="#" onClick={() => navigate('/')}>
      HOME
    </Link>,
    <Link underline="hover" key="2" color="inherit">
      Monitor
    </Link>
  ];

  return (
    <Layout 
      theme={THEME}
      showHeaderSpacing={false}
      color={ThemeVariation.Base}
    >
      <Container>
        <Stack spacing={2} mb={3} mt={4}>
          <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
            {breadcrumbs}
          </Breadcrumbs>
        </Stack>
        <Grid item xs={12} md={4} textAlign="right">
          <Button onClick={handleReload} color="primary" variant="contained" startIcon={<RefreshIcon />}>
            Refresh
          </Button>
          {/* <IconButton onClick={handleReload} color="primary">
            <RefreshIcon /> 
          </IconButton> */}
        </Grid>
        <Grid container justifyContent="center" >
          <Grid item xs={12} md={4}>
            <Card sx={{ minWidth: 355, mt: 2 }}>
              <CardContent>
                <Typography variant="h4" component="div" textAlign="center">
                  Success Orders
                </Typography>
                <Typography variant="h1" component="div" textAlign="center">
                  <CountUp start={0} end={data?.successOrder} duration={2} />
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          
          <Grid container spacing={4} justifyContent="left" mt={2}>
            {data?.participans?.map((i, index) => (
              <Grid item xs={12} md={4} key={index}>
                <Card>
                  <CardContent>
                    <Typography variant="h5" component="div" textAlign="center">
                      {i.ticket_name}
                    </Typography>
                    <Typography variant="h2" component="div" textAlign="center">
                      <CountUp start={0} end={i.count} duration={2} />
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Container>
    </Layout>
  );
};

export default Monitor;
