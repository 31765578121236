import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FieldArray, Field, ErrorMessage } from 'formik';
import { Grid, TextField, MenuItem, Button, Typography, Divider } from '@mui/material';
import DatePickerField from "../element/date-picker/DatePickerField";
import "react-datepicker/dist/react-datepicker.css";
import { commonActions } from '../../store/slices/common';
import { handleGetJersey } from '../../store/thunk/jersey.js';

const bloodTypeOptions = [
  { value: 'A', label: 'A' },
  { value: 'B', label: 'B' },
  { value: 'AB', label: 'AB' },
  { value: 'O', label: 'O' }
];

const genderOptions = [
  { value: 'male', label: 'Male' },
  { value: 'female', label: 'Female' }
];

const FormParticipants = ({ name, setForm, ticketId, ticketType }) => {
  const dispatch = useDispatch();
  const { unisex, kids } = useSelector((state) => state.jersey);

  useEffect(() => {
    dispatch(handleGetJersey('unisex', '/unisex'));
    dispatch(handleGetJersey('kids', '/kids'));
  }, [dispatch]);
  
  let jerseyUnisex = [];
  let jerseyKids = [];
  for(let i = 0; i < unisex.data.length; i ++){
    jerseyUnisex.push({
      value: `${unisex.data[i].size_id}_${unisex.data[i].size_tag} (width ${unisex.data[i].size_width}cm / length ${unisex.data[i].size_length})`,
      label: `${unisex.data[i].size_tag} (width ${unisex.data[i].size_width}cm / length ${unisex.data[i].size_length})`
    })
  }
  for(let i = 0; i < kids.data.length; i ++){
    jerseyKids.push({
      value: `${kids.data[i].size_id}+${i}_${kids.data[i].size_tag} (width ${kids.data[i].size_width}cm / length ${kids.data[i].size_length})`,
      label: `${kids.data[i].size_tag} (width ${kids.data[i].size_width}cm / length ${kids.data[i].size_length})`
    })
  }
  const objParticipant = {
    ticket_id: ticketId,
    fullName: '',
    email: '',
    birth: '', 
    gender: '', 
    nik: '', 
    phone: '', 
    nationality: '', 
    address: '', 
    bloodType: '', 
    emergencyContactName: '', 
    emergencyContactPhone: '', 
    jerseySize: '', 
    jerseySizeKids: '',
    diseaseHistory: '',
  }
  const handleAddParticipant = (push, form) => {
    const participants = form.values[name] || [];
    
    if (name === 'formComTenK' || name === 'formComFiveK') {
      for (let i = 0; i < 5; i++) {
        if (participants.length < 5) {
          push(objParticipant);
        } else {
          dispatch(commonActions.showToast({ message: 'Maximum of 5 participants can be added for Commu', type: 'warn' }));
          break;
        }
      }
    }else{
      if (participants.length < 3) {
        push(objParticipant);
      } else {
        dispatch(commonActions.showToast({ message: 'Maximum of 3 participants can be added', type: 'warn' }));
      }
    }
  };

  const handleRemoveAllParticipants = (remove, ticket_tag) => {
    if (ticket_tag === 'formComTenK' || ticket_tag === 'formComFiveK') {
      while (remove(0)) {} 
    }
  };
  
  const radius = {
    '& .MuiOutlinedInput-root': {
      borderRadius: '20px',
    },
  };
  return (
    <FieldArray name={name}>
      {({ form, push, remove }) => {
        const participants = form.values[name] || []; // Default to empty array
        return(
          <>
          {participants.map((_, index) => (
            <Grid container spacing={2} key={index}>
               <Grid item xs={12}>
                <Typography variant="h6" gutterBottom sx={{ mt: 3 }}>
                 Participant {index + 1}
                </Typography>
              </Grid>
              <Grid item md={6} xs={12}>
                {/* <FormikTextFieldIndex index={index} name={`${name}[${index}].fullName`} label="Full Name" /> */}
                <Field
                  as={TextField}
                  name={`${name}[${index}].fullName`}
                  label="Full Name"
                  fullWidth
                  variant="outlined"
                  error={!!form.errors[name]?.[index]?.fullName && form.touched[name]?.[index]?.fullName}
                  helperText={<ErrorMessage name={`${name}[${index}].fullName`} />}
                  sx={radius}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                {/* <FormikTextFieldIndex index={index} name={`${name}[${index}].email`} label="Email" /> */}
                <Field
                  as={TextField}
                  name={`${name}[${index}].email`}
                  label="Email"
                  fullWidth
                  variant="outlined"
                  error={!!form.errors[name]?.[index]?.email && form.touched[name]?.[index]?.email}
                  helperText={<ErrorMessage name={`${name}[${index}].email`} />}
                  sx={radius}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <DatePickerField name={`${name}[${index}].birth`} label="Birth Date" />
              </Grid>
              <Grid item md={6} xs={12}>
                <Field
                  as={TextField}
                  name={`${name}[${index}].nik`}
                  label="KTP/Passport"
                  fullWidth
                  variant="outlined"
                  error={!!form.errors[name]?.[index]?.nik && form.touched[name]?.[index]?.nik}
                  helperText={<ErrorMessage name={`${name}[${index}].nik`} />}
                  sx={radius}
                />
                <Typography variant="caption" color="textSecondary">
                 For children, use the parent/guardian's ID card
                </Typography>
              </Grid>
              <Grid item md={6} xs={12}>
                <Field
                  as={TextField}
                  name={`${name}[${index}].phone`}
                  label="Phone"
                  fullWidth
                  variant="outlined"
                  error={!!form.errors[name]?.[index]?.phone && form.touched[name]?.[index]?.phone}
                  helperText={<ErrorMessage name={`${name}[${index}].phone`} />}
                  sx={radius}
                />
                <Typography variant="caption" color="textSecondary">
                  Whatsapp number active
                </Typography>
              </Grid>
              <Grid item md={6} xs={12}>
                <Field
                  as={TextField}
                  name={`${name}[${index}].nationality`}
                  label="Nationality"
                  fullWidth
                  variant="outlined"
                  error={!!form.errors[name]?.[index]?.nationality && form.touched[name]?.[index]?.nationality}
                  helperText={<ErrorMessage name={`${name}[${index}].nationality`} />}
                  sx={radius}
                />
              </Grid>
              <Grid item md={12} xs={12}>
                <Field
                  as={TextField}
                  name={`${name}[${index}].address`}
                  label="Full Address"
                  fullWidth
                  variant="outlined"
                  error={!!form.errors[name]?.[index]?.address && form.touched[name]?.[index]?.address}
                  helperText={<ErrorMessage name={`${name}[${index}].address`} />}
                  sx={radius}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <Field
                  as={TextField}
                  select
                  name={`${name}[${index}].bloodType`}
                  label="Blood Type"
                  fullWidth
                  variant="outlined"
                  error={!!form.errors[name]?.[index]?.bloodType && form.touched[name]?.[index]?.bloodType}
                  helperText={<ErrorMessage name={`${name}[${index}].bloodType`} />}
                  sx={radius}
                >
                  <MenuItem value="">
                    <em>Select Blood Type</em>
                  </MenuItem>
                  {bloodTypeOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Field>
              </Grid>
              <Grid item md={6} xs={12}>
                <Field
                  as={TextField}
                  select
                  name={`${name}[${index}].gender`}
                  label="Gender"
                  fullWidth
                  variant="outlined"
                  error={!!form.errors[name]?.[index]?.gender && form.touched[name]?.[index]?.gender}
                  helperText={<ErrorMessage name={`${name}[${index}].gender`} />}
                  sx={radius}
                >
                  <MenuItem value="">
                    <em>Select Gender</em>
                  </MenuItem>
                  {genderOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Field>
              </Grid>
              <Grid item md={6} xs={12}>
                <Field
                  as={TextField}
                  select
                  name={`${name}[${index}].jerseySize`}
                  label="Jersey Size"
                  fullWidth
                  variant="outlined"
                  error={!!form.errors[name]?.[index]?.jerseySize && form.touched[name]?.[index]?.jerseySize}
                  helperText={
                    !!form.errors[name]?.[index]?.jerseySize && form.touched[name]?.[index]?.jerseySize
                      ? form.errors[name][index].jerseySize
                      : form.errors[name]?.[index]?.jerseySizeKids && form.touched[name]?.[index]?.jerseySizeKids
                      ? form.errors[name][index].jerseySizeKids
                      : ''
                  }
                  sx={radius}
                >
                  <MenuItem value="">
                    <em>Select Jersey Size</em>
                  </MenuItem>
                  {jerseyUnisex.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Field>
                <Typography variant="caption" color="textSecondary">
                  Jersey for Adult Participants
                </Typography>
              </Grid>
              {
                ticketType === 'family' && 
                <Grid item md={6} xs={12}>
                  <Field
                    as={TextField}
                    select
                    name={`${name}[${index}].jerseySizeKids`}
                    label="Jersey Size Kids"
                    fullWidth
                    variant="outlined"
                    error={!!form.errors[name]?.[index]?.jerseySizeKids && form.touched[name]?.[index]?.jerseySizeKids}
                    helperText={
                      !!form.errors[name]?.[index]?.jerseySizeKids && form.touched[name]?.[index]?.jerseySizeKids
                        ? form.errors[name][index].jerseySizeKids
                        : form.errors[name]?.[index]?.jerseySize && form.touched[name]?.[index]?.jerseySize
                        ? form.errors[name][index].jerseySize
                        : ''
                    }
                    sx={radius}
                  >
                    <MenuItem value="">
                      <em>Select Jersey Size</em>
                    </MenuItem>
                    {jerseyKids.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Field>
                  <Typography variant="caption" color="textSecondary">
                    Jersey for Child Participants
                  </Typography>
                </Grid>
              }
              <Grid item md={6} xs={12}>
                <Field
                  as={TextField}
                  name={`${name}[${index}].diseaseHistory`}
                  label="Disease History"
                  fullWidth
                  multiline
                  variant="outlined"
                  error={!!form.errors[name]?.[index]?.diseaseHistory && form.touched[name]?.[index]?.diseaseHistory}
                  helperText={<ErrorMessage name={`${name}[${index}].diseaseHistory`} />}
                  sx={radius}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <Field
                  as={TextField}
                  name={`${name}[${index}].emergencyContactName`}
                  label="Emergency Contact Name"
                  fullWidth
                  variant="outlined"
                  error={!!form.errors[name]?.[index]?.emergencyContactName && form.touched[name]?.[index]?.emergencyContactName}
                  helperText={<ErrorMessage name={`${name}[${index}].emergencyContactName`} />}
                  sx={radius}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <Field
                  as={TextField}
                  name={`${name}[${index}].emergencyContactPhone`}
                  label="Emergency Contact Phone"
                  fullWidth
                  variant="outlined"
                  error={!!form.errors[name]?.[index]?.emergencyContactPhone && form.touched[name]?.[index]?.emergencyContactPhone}
                  helperText={<ErrorMessage name={`${name}[${index}].emergencyContactPhone`} />}
                  sx={radius}
                />
                 <Typography variant="caption" color="textSecondary">
                  Whatsapp number active
                </Typography>
              </Grid>
              {
               ( name === 'formComTenK' || name === 'formComFiveK') ? null :
               <Grid item md={12} xs={12}>
                  <Button
                    variant="contained"
                    color="error"
                    sx={{ borderRadius: '20px'}}
                    onClick={() => remove(index)}
                  >
                    - Remove Participant
                  </Button>
                </Grid>
              }
              <Grid item xs={12}>
                <Divider />
              </Grid>
            </Grid>
          ))}
          <Grid item md={12} xs={12}>
            <Button 
              type="button" 
              onClick={() => {
                handleAddParticipant(push, form)
              }}
            >
            {participants.length === 0 ? '+ Get Ticket' : '+ Get More Ticket'}
          </Button>
        </Grid>
        {
        (name === 'formComTenK' && form.values['formComTenK'].length > 1) || (name === 'formComFiveK' && form.values['formComFiveK'].length > 1) ? 
          <Button
            type="button"
            variant="contained"
            color="error"
            onClick={() => handleRemoveAllParticipants(remove, name)}
          >
            - Remove All Participants
          </Button>  : null
        }
        </>
        );
      }}
    </FieldArray>
  );
};

export default FormParticipants;
