import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  detail: {
    fetching: false,
    error: null,
    data: null,
  },
  list: {
    data: [],
    fetching: false,
    error: null,
  },
  tickets: {
    fetching: false,
    error: null,
    data: [],
  },
};

const getEventRequest = (state) => {
  delete state.list.error;
  state.list.fetching = true;
};

const getEventSuccess = (state, action) => {
  state.list.data = action.payload;
  state.list.fetching = false;
};

const getEventFailed = (state, action) => {
  state.list.error = action.payload;
  state.list.fetching = false;
};

const getEventDetailRequest = (state) => {
  delete state.detail.error;
  state.detail.fetching = true;
};

const getEventDetailSuccess = (state, { payload }) => {
  state.detail.fetching = false;
  state.detail.data = payload;
};

const getEventDetailFailed = (state, { payload }) => {
  if (state.detail) {
    delete state.detail.error;
    state.detail.fetching = false;
    state.detail.error = payload;
  } else {
    state.detail = {
      fetching: false,
      error: payload
    };
  }
};

const getTicketRequest = (state) => {
  delete state.tickets.error;
  state.tickets.fetching = true;
};

const getTicketSuccess = (state, { payload }) => {
  state.tickets.fetching = false;
  state.tickets.data = payload;
};

const getTicketFailed = (state, { payload }) => {
  if (state.tickets) {
    delete state.tickets.error;
    state.tickets.fetching = false;
    state.tickets.error = payload;
  } else {
    state.tickets = {
      fetching: false,
      error: payload
    };
  }
};

const eventSlice = createSlice({
  name: 'event',
  initialState, // Add the initialState here
  reducers: {
    getEventRequest,
    getEventSuccess,
    getEventFailed,

    getEventDetailRequest,
    getEventDetailSuccess,
    getEventDetailFailed,

    getTicketRequest,
    getTicketSuccess,
    getTicketFailed,
  },
});

export const eventActions = eventSlice.actions;
export const eventReducer = eventSlice.reducer;
