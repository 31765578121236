import React from 'react';
import InvoiceTemplate from './InvoiceTemplate'; // Adjust path based on your project structure
import CountdownTimer from './CountdownTimer'; // Adjust path based on your project structure
import Layout from '../layout';
import { Container, Box } from '@mui/material';
import ThemeVariation from '../../theme/theme-variation';
import { useLocation } from 'react-router-dom';

const SampleInvoicePage = () => {

  const location = useLocation();
  const data = location.state; // Access the data passed via state

  const THEME = ThemeVariation.Secondary;

  return (
    <Layout theme={THEME} showHeaderSpacing={false} color={ThemeVariation.Confirm}>
      <Container>
        {/* <Box display="flex" justifyContent="center">
          <CountdownTimer expiryDate={Date.now()} />
        </Box> */}
        <InvoiceTemplate data={data} />
      </Container>
    </Layout>
  );
};

export default SampleInvoicePage;
